<template>
    <div class="otpCompClass">
        <!-- <v-app> -->
            <div class="OTPFontClass" style="text-align: left">OTP</div>
            <div class="d-flex flex-row align-center"> 
                <div style="width: 80%">
                    <v-otp-input
                    class="otp-btn"
                    v-model="otpValue"
                    :should-auto-focus="true"
                    length="6"
                    type="number"
                    @input="onChange"
                    @finish="onFinish"
                    ></v-otp-input>
                </div>
                <div v-if="!enableCreateUser" class="d-flex flex-row createUserDivClass" style="width:19%;cursor: pointer;">
                    <span class="createUserDivFontClass">
                        Create User
                    </span>
                </div>
                <div v-if="enableCreateUser" class="d-flex flex-row enableCreateUserDivClass" @click="createUser()" style="width:19%;cursor: pointer;">
                    <span class="createUserDivFontClass">
                        Create User
                    </span>
                </div>
            </div>
        <!-- </v-app> -->
    </div>
</template>

<script>
import axios from "axios";
import { axios_auth_instance_epharmacy_admin } from '../utils/axios_utils';
export default {
name: "OTPComponent",
props:['userData', 'otpValueFromUserPage'],
components : {
},
computed: {
},
data() {
    return {
        otpValue: '',
        enableCreateUser: false,
        pageName: 'OTPComponent',
        cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
        editIcon: 'editIcon.svg',
        deleteIcon: 'deleteIcon.svg',
    }
},
methods: {
    async createUser() {
        var otp_body = {
            otpProvided: this.otpValue,
            phoneNumber: this.userData.phoneNumber
        };
		let verifyOTPResponse = await axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyOTP", otp_body);
        if(verifyOTPResponse && verifyOTPResponse.data && verifyOTPResponse.data.message == 'SUCCESS') {
            alert('OTP is Verified');
            let addNewUserResponse = await axios.post(process.env.VUE_APP_BACKEND_URL+"/addNewUser", this.userData);
            console.log('addNewUserResponse', addNewUserResponse);
            if(addNewUserResponse && addNewUserResponse.data && addNewUserResponse.data.message == 'SUCCESS') {
                alert('User is Created in DynamoDB');
                
                let createUserAddressResponse = await axios_auth_instance_epharmacy_admin.post("/admin/user/createAddressInOdoo", {
                    'deliverTo': this.userData.customerName,
                    'partnerId': addNewUserResponse.data.data,
                    'stateId': this.userData.deliveryAddress[0].state,
                    'phoneNumber': this.userData.phoneNumber.toString(),
                    'pinCode': this.userData.deliveryAddress[0].pinCode,
                    'landmark': this.userData.deliveryAddress[0].landMark,
                    'street2': this.userData.deliveryAddress[0].streetName,
                    'street': this.userData.deliveryAddress[0].buildingName,
                    'addressTypeName': this.userData.deliveryAddress[0].addressType,
                });
                
                console.log('createUserAddressResponse', createUserAddressResponse);
                this.$router.push({
                    name: 'UserManagement',
                })
            }
        } else {
            alert('OTP is wrong');
        }
    },
    onChange(otp) {
        this.onFinish(otp)
    },
    onFinish(otp){
        this.otpValue = otp;
        console.log('this.otpValue', typeof this.otpValue)
        if(this.otpValue.length == 6) {
            this.enableCreateUser = true;
        } else {
            this.enableCreateUser = false;
        }
    },
},
async mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
        this.$router.push({
            name: "AdminLogin",
        });
    } else {
        this.userExistIcon = this.cloudFront + this.userExistIcon;
    }
    this.otpValue = this.otpValueFromUserPage;
},
}
</script>

<style>
.otpCompClass {
    padding: 24px;
    align-items: flex-start;
    max-height: 200px;
    gap: 24px;
    border-radius: 12px;
    border: 1px solid var(--grays-gray-5, #E0E0E0);
    background: var(--pure-white, #FFF);
}
.otp-btn {
    max-width: 300px;
}
.OTPFontClass{
    color: var(--grays-gray-1, #333);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.createUserDivClass {
    display: flex;
    width: 176px;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    background: var(--grays-gray-4, #BDBDBD);
}
.createUserDivFontClass {
    color: var(--main-color-white, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
}
.enableCreateUserDivClass {
    display: flex;
    width: 176px;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    background: #219653;
}
.createNewUserFontClass {
    color: var(--main-color-white, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
}
</style>